/*
Template Name: Canada Eat - Online Food Ordering Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
-- Body
-- Nav
-- login/Register
-- Homepage Search Block
-- Homepage
-- Customize Bootstrap
-- Listing
-- Detail
-- Checkout Page
-- My Account
-- Track Order Page
-- Footer
-- Mobile Media
*/
/* Body */
body {
	background: #f3f7f8;
	font-size: 13px;
	font-weight: 500;
}
button,
a {
	outline: none !important;
	color: rgb(0, 151, 136);
	text-decoration: none !important;
	font-size: 14px;
}
p {
	font-size: 13px;
	color: #7a7e8a;
}
img {
	image-rendering: auto;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000000;
}
/* Nav */
.osahan-nav {
	background: #fff none repeat scroll 0 0 !important;
	padding: 0;
	z-index: 9;
}
.osahan-nav .nav-link {
	font-weight: 600;
	padding: 28px 0px !important;
	margin: 0 0 0 31px;
}
.dropdown-cart .dropdown-menu {
	padding-top: 0px !important;
}
.nav-osahan-pic {
	width: 32px;
	height: 32px;
	border: 3px solid #fff;
	box-shadow: 0px 0px 3px #ccc;
	position: relative;
	margin: -8px 5px -6px 0;
	vertical-align: text-top;
}
.dropdown-cart-top {
	border-top: 2px solid rgb(0, 151, 136);
	min-width: 340px;
}
.dropdown-cart-top-header {
	min-height: 107px;
}
.dropdown-cart-top-header .img-fluid {
	border: 1px solid #dcdcdc;
	border-radius: 3px;
	float: left;
	height: 59px;
	padding: 3px;
	width: 59px;
}
.dropdown-cart-top-header p.text-secondary {
	font-size: 11px;
	line-height: 24px;
	font-weight: 600;
}
.dropdown-cart-top-header h6 {
	font-size: 14px;
}
.food-item {
	border: 1px solid;
	border-radius: 2px;
	display: inline-block;
	font-size: 6px;
	height: 13px;
	line-height: 12px;
	text-align: center;
	width: 13px;
}
.dropdown-cart-top-body .food-item {
	float: left;
	margin: 4px 10px 0 0;
	padding-left: 1px;
}
/* login/Register */
:root {
	--input-padding-x: 1.5rem;
	--input-padding-y: 0.75rem;
}
.login,
.image {
	min-height: 100vh;
}
.bg-image {
	background-image: url("/img/login-bg.png");
	background-size: cover;
	background-position: center;
}
.login-heading {
	font-weight: 300;
}
.btn-login {
	font-size: 0.9rem;
	letter-spacing: 0.05rem;
	padding: 0.75rem 1rem;
	border-radius: 2rem;
}
.form-label-group {
	position: relative;
	margin-bottom: 1rem;
}
.form-label-group > input,
.form-label-group > label {
	padding: 15px 0;
	border-radius: 0px;
	height: 51px;
	background: transparent !important;
	border: none;
	border-bottom: 1px solid #ced4da;
	box-shadow: none !important;
}
.form-label-group > label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0;
	/* Override default `<label>` margin */
	line-height: 1.5;
	color: #495057;
	cursor: text;
	/* Match the input under the label */
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: all 0.1s ease-in-out;
}
.form-label-group input::-webkit-input-placeholder {
	color: transparent;
}
.form-label-group input:-ms-input-placeholder {
	color: transparent;
}
.form-label-group input::-ms-input-placeholder {
	color: transparent;
}
.form-label-group input::-moz-placeholder {
	color: transparent;
}
.form-label-group input::placeholder {
	color: transparent;
}
.form-label-group input:not(:placeholder-shown) {
	padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
	padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group input:not(:placeholder-shown) ~ label {
	padding-top: calc(var(--input-padding-y) / 3);
	padding-bottom: calc(var(--input-padding-y) / 3);
	font-size: 12px;
	color: #777;
}
.btn-google {
	color: white;
	background-color: #ea4335;
}
.btn-facebook {
	color: white;
	background-color: #3b5998;
}
/* Homepage Search Block */
.homepage-search-block {
	background-color: #eef0ef;
}
.homepage-search-form .form-control {
	font-size: 15px;
	height: 50px;
	padding-right: 106px;
}
.homepage-search-form .form-group {
	position: relative;
}
.homepage-search-form .locate-me {
	background: #ececec none repeat scroll 0 0;
	color: #666;
	font-size: 12px;
	padding: 7px 10px;
	border-radius: 3px;
	position: absolute;
	right: 14px;
	top: 9px;
}
.homepage-search-form .locate-me:hover {
	background: #666 none repeat scroll 0 0;
	color: #ffffff;
}
.homepage-search-form .btn {
	font-size: 15px;
	font-weight: 600;
	height: 50px;
	padding: 13px 5px;
}
.homepage-search-form .location-dropdown i {
	left: 18px;
	position: absolute;
	top: 20px;
	z-index: 9;
}
.homepage-search-form .select2-selection {
	border-color: #ced4da !important;
	border-radius: 0.25rem !important;
	height: 50px !important;
	outline: none !important;
}
.homepage-search-form .select2-selection__rendered {
	font-size: 15px;
	line-height: 47px !important;
	padding: 0 28px 0 31px !important;
}
.homepage-search-form .select2-selection__arrow {
	right: 9px !important;
	top: 13px !important;
}
.select2-dropdown {
	margin: -2px 0 0 0;
	border: 1px solid #ced4da;
	border-radius: 3px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #ced4da;
}
.select2-search__field {
	outline: none !important;
	border: 1px solid #ced4da;
}
.owl-carousel {
	position: relative;
}
.owl-theme .owl-nav [class*="owl-"] {
	position: absolute;
	bottom: calc(50% - 20px);
	background: #fff none repeat scroll 0 0 !important;
	border-radius: 30px !important;
	box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
	color: #000 !important;
	height: 40px;
	line-height: 40px;
	margin: 0 !important;
	text-align: center;
	text-transform: uppercase;
	width: 40px;
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.owl-theme .owl-nav [class*="owl-"] i {
	font-size: 13px !important;
	font-weight: normal !important;
}
.owl-theme .owl-nav .owl-prev {
	margin-left: -16px !important;
}
.owl-theme .owl-nav .owl-next {
	right: 0px;
	margin-right: -16px !important;
}
/* Homepage */
.owl-carousel-four .item {
	padding: 6px 7px;
}
.osahan-category-item {
	background: #fff;
	margin: 5px 2px;
	border-radius: 15px;
	width: 100%;
	/*overflow: hidden;*/
	text-align: center;
	padding: 7px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	border: 1px solid #fff;
}
.osahan-category-item:hover {
	border: 1px solid rgb(0, 151, 136);
}
.osahan-category-item h6 {
	margin: 0px;
	width: 100%;
	font-size: 15px;
	color: #000000;
}
.osahan-category-item p {
	margin: 0px;
	font-size: 12px;
}
.osahan-category-item img {
	width: 40px !important;
	height: 40px;
	object-fit: cover;
	display: inline-block !important;
	border-radius: 50px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	margin: 2px 0 8px 0;
}
.line {
	background-color: #5e5e5e;
	border-radius: 12px;
	display: inline-table;
	height: 4px;
	margin: 0 0 51px;
	width: 50px;
}
/* Customize Bootstrap */
.small,
small {
	font-size: 85%;
	font-weight: 600;
}
.select2,
.select2 span {
	width: 100% !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	left: 96%;
}
.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 3px;
}
.modal-footer .btn {
	font-size: 14px;
	padding: 12px 16px;
}
.btn-success,
.badge-success {
	background-color: rgb(0, 151, 136) !important;
	border-color: rgb(0, 151, 136) !important;
}
.btn-outline-success {
	color: #000 !important;
	border-color: #000 !important;
	background-color: #000 !important;
}
.btn-outline-success:hover {
	color: #ffffff !important;
	border-color: rgb(0, 151, 136) !important;
	background-color: rgb(0, 151, 136) !important;
}
.text-success {
	color: rgb(0, 151, 136) !important;
}
.border-success {
	border-color: #233033 !important;
}
.btn-danger,
.badge-danger {
	background-color: #f32129 !important;
	border-color: #f32129 !important;
}
.btn-outline-danger {
	color: #f32129 !important;
	border-color: #f32129 !important;
}
.btn-outline-danger:hover {
	color: #ffffff !important;
	border-color: #f32129 !important;
	background-color: #f32129 !important;
}
.text-danger {
	color: #f32129 !important;
}
.border-danger {
	border-color: #f32129 !important;
}
.btn-primary,
.badge-danger {
	background-color: rgb(0, 151, 136) !important;
	border-color: rgb(0, 151, 136) !important;
}
.btn-outline-primary {
	color: rgb(0, 151, 136) !important;
	border-color: rgb(0, 151, 136) !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
	color: #ffffff !important;
	border-color: rgb(0, 151, 136) !important;
	background-color: rgb(0, 151, 136) !important;
}
.text-primary {
	color: rgb(0, 151, 136) !important;
}
.border-primary {
	border-color: rgb(0, 151, 136) !important;
}
.btn-lg {
	font-size: 15px;
	font-weight: bold;
	padding: 14px 35px;
	letter-spacing: 1px;
}
.modal-content {
	border: none;
	border-radius: 2px;
}
.btn {
	border-radius: 2px;
}
.form-control {
	font-size: 16px;
	border-radius: 2px;
	min-height: 38px;
}
.card {
	border-radius: 2px;
}
.rounded {
	border-radius: 2px !important;
}
.input-group-text {
	border-radius: 2px;
}
.custom-checkbox .custom-control-label::before {
	border-radius: 2px;
}
.nav-pills .nav-link {
	border-radius: 2px;
}
.alert,
.badge,
.dropdown-menu {
	border-radius: 2px;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
	border-radius: 2px;
}
.bg-dark {
	background-color: #000 !important;
}
.pb-5,
.py-5 {
	padding-bottom: 4rem !important;
}
.pt-5,
.py-5 {
	padding-top: 4rem !important;
}
.dropdown-menu {
	font-size: 13px;
}
.dropdown-item {
	border-radius: 0 !important;
	padding: 7px 18px;
	color: rgba(0, 0, 0, 0.5);
	font-weight: 500;
}
.dropdown-item:focus {
	background-color: rgb(0, 151, 136);
	color: #fff;
}
/* Listing */
.list-cate-page {
	margin: -5px 0px 0px 0px;
}
.filters-card-body {
	padding: 18px 0 0 0;
}
.filters-search {
	position: relative;
}
.filters-search i {
	position: absolute;
	left: 12px;
	top: 13px;
}
.filters-search .form-control {
	padding-left: 34px;
}
.custom-checkbox label.custom-control-label,
.custom-radio label.custom-control-label {
	padding-top: 2px;
	cursor: pointer;
}
.filters-card-header a {
	font-weight: 500;
	width: 100%;
	display: block;
}
.filters-card-header a i {
	margin: 2px -2px 0 0;
}
.position-absolute {
	z-index: 1;
}
iframe.position-absolute {
	z-index: 0;
}
.list-card .count-number {
	margin: 4px 0 0 0;
	display: inline-block;
}
.list-card a {
	color: inherit;
}
.list-card a:hover {
	color: #0091bc;
}
.list-card .star {
	right: 8px;
	bottom: 8px;
}
.list-card .star .badge {
	font-size: 11px;
	padding: 5px 5px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .member-plan {
	left: 8px;
	top: 8px;
}
.list-card .member-plan .badge {
	font-size: 11px;
	padding: 5px 5px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .list-card-image {
	position: relative;
}
.list-card .favourite-heart {
	right: 8px;
	top: 8px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.list-card .favourite-heart i {
	background: #fff;
	width: 10px;
	height: 15px;
	display: inline-block;
	text-align: center;
}
/* detail */
.count-number .btn {
	padding: 2px 5px;
	font-size: 12px;
	border-radius: 0px;
}
.restaurant-detailed-header-left {
	text-shadow: 0px 0px 10px #000;
}
.count-number-input {
	width: 24px;
	text-align: center;
	margin: 0 -4px;
	background: #6c757d;
	border: none;
	color: #fff;
	height: 24px;
	border-radius: 0px;
	vertical-align: bottom;
	font-size: 16px;
}
.generator-bg {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/rgb(0, 151, 136)+1,758b24+100 */
	background: #f5f0ef; /* Old browsers */
	background: -moz-linear-gradient(
		-45deg,
		#edf1f2 1%,
		#edf1f2 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		-45deg,
		#edf1f2 1%,
		#edf1f2 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		135deg,
		#edf1f2 1%,
		#edf1f2 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edf1f2', endColorstr='#edf1f2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.restaurant-detailed-banner {
	position: relative;
}
.restaurant-detailed-banner .img-fluid.cover {
	height: auto;
	object-fit: cover;
}
.restaurant-detailed-header {
	bottom: 0;
	left: 0;
	padding: 65px 0 17px;
	position: absolute;
	right: 0;
	background: -moz-linear-gradient(
		top,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.83) 100%
	);
	background: -webkit-linear-gradient(
		top,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.83) 100%
	);
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.83) 100%
	);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
}
.homepage-great-deals-carousel .item img {
	border-radius: 2px;
}
.restaurant-detailed-earn-pts .img-fluid.float-left {
	height: 61px;
}
.card-icon-overlap {
	overflow: hidden;
	position: relative;
}
.red-generator-bg {
	background: #ff3371;
	background: -moz-linear-gradient(-45deg, #ff3371 1%, #fe6753 100%);
	background: -webkit-linear-gradient(-45deg, #ff3371 1%, #fe6753 100%);
	background: linear-gradient(135deg, #ff3371 1%, #fe6753 100%);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff3371', endColorstr='#fe6753', GradientType=1);
}
.border-btn {
	border: 1px solid #dee0e6;
	border-radius: 2px;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	padding: 8px 15px;
	margin: 0 0 10px 0;
}
.restaurant-detailed-ratings .generator-bg {
	border-radius: 4px;
	display: inline-block;
	font-size: 12px;
	height: 20px;
	margin: 0 4px 0 0;
	padding: 3px 6px;
}
.explore-outlets-search .form-control {
	border-radius: 2px !important;
	font-size: 16px;
	height: 50px;
	padding: 5px 20px;
}
.explore-outlets-search .btn {
	color: #777777;
	font-size: 16px;
	padding: 13px 17px;
	position: absolute;
	z-index: 9;
	right: 0;
	text-decoration: none;
}
.mall-category-item {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	margin: 0 6px;
	overflow: hidden;
	text-align: center;
	padding: 0 0 7px 0;
}
.mall-category-item img {
	height: 89px;
	object-fit: cover;
	width: 100%;
}
.mall-category-item h6 {
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	padding: 10px 10px 3px 10px;
}
.mall-category-item .btn {
	padding: 2px 8px;
	font-size: 12px;
	right: 7px;
	top: 7px;
}
.mall-category-item small {
	background: #ececec;
	color: #f32129;
	padding: 1px 6px;
	border-radius: 2px;
	font-size: 10px;
}
.mall-category-item:hover small {
	background: #f32129;
	color: #ffffff;
}
.address-map {
	border-radius: 2px;
	overflow: hidden;
}
.new-arrivals-card .card-img .new-arrivals {
	bottom: 11px;
	left: 12px;
	position: absolute;
}
.new-arrivals {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	padding: 1px 13px;
	text-transform: uppercase;
}
.new-arrivals-card .card-img {
	position: relative;
}
.total-like-user {
	border: 2px solid #fff;
	height: 34px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	width: 34px;
}
.total-like-user-main a {
	display: inline-block;
	margin: 0 -17px 0 0;
}
.total-like {
	border: 1px solid;
	border-radius: 50px;
	display: inline-block;
	font-weight: 500;
	height: 34px;
	line-height: 33px;
	padding: 0 13px;
	vertical-align: top;
}
.restaurant-detailed-ratings-and-reviews hr {
	margin: 0 -24px;
}
.star-rating {
	cursor: pointer;
}
.graph-star-rating-header .star-rating {
	font-size: 17px;
}
.progress {
	background: #f2f4f8 none repeat scroll 0 0;
	border-radius: 0;
	height: 30px;
}
.rating-list {
	display: inline-flex;
	margin-bottom: 15px;
	width: 100%;
}
.rating-list-left {
	height: 16px;
	line-height: 29px;
	width: 10%;
}
.rating-list-center {
	width: 80%;
}
.rating-list-right {
	line-height: 29px;
	text-align: right;
	width: 10%;
}
.restaurant-slider-pics {
	bottom: 0;
	font-size: 12px;
	left: 0;
	z-index: 999;
	padding: 0 10px;
}
.restaurant-slider-view-all {
	bottom: 15px;
	right: 15px;
	z-index: 999;
}
.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
	border-color: #3868fb;
	color: #3868fb;
}
.offer-dedicated-nav .nav-link {
	border-bottom: 2px solid #fff;
	color: #000000;
	padding: 16px 0;
	font-weight: 600;
	font-size: 18px;
}
.offer-dedicated-nav .nav-item {
	margin: 0 37px 0 0;
}
.restaurant-detailed-action-btn {
	margin-top: 12px;
}
.restaurant-detailed-header-right .btn-success {
	border-radius: 3px;
	height: 45px;
	margin: -18px 0 18px;
	min-width: 130px;
	padding: 7px;
}
.text-black {
	color: #000000;
	font-size: 14px;
}
.icon-overlap {
	bottom: -23px;
	font-size: 74px;
	opacity: 0.23;
	position: absolute;
	right: -32px;
}
.menu-list img {
	width: 41px;
	height: 41px;
	object-fit: cover;
}
.restaurant-detailed-header-left img {
	width: 88px;
	height: 88px;
	border-radius: 3px;
	object-fit: cover;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
/* Checkout Page */
.addresses-item a.btn.btn-sm {
	min-width: 157px;
}
.osahan-cart-item-profile img {
	width: 40px;
	height: 40px;
	border: 3px solid #fff;
}
.osahan-cart-item-profile p {
	font-size: 10px;
}
.osahan-payment .nav-link {
	padding: 18px 20px;
	border: none;
	font-weight: 600;
	color: #535665;
}
.osahan-payment .nav-link i {
	width: 28px;
	height: 28px;
	background: #535665;
	display: inline-block;
	text-align: center;
	line-height: 29px;
	font-size: 15px;
	border-radius: 50px;
	margin: 0 7px 0 0px;
	color: #fff;
}
.osahan-payment .nav-link.active {
	background: #f3f7f8;
	color: #282c3f !important;
}
.osahan-payment .nav-link.active i {
	background: #282c3f !important;
}
.osahan-payment .tab-content {
	background: #f3f7f8;
	padding: 20px;
}
.osahan-card i {
	font-size: 35px;
	vertical-align: middle;
	color: #6c757d;
}
.osahan-card {
	margin: 0 0 0 7px;
}
.osahan-payment label {
	font-size: 12px;
	margin: 0 0 3px 0;
	font-weight: 600;
}
/* My Account */
.payments-item img.mr-3 {
	width: 47px;
}
.order-list .btn {
	border-radius: 2px;
	min-width: 121px;
	font-size: 13px;
	padding: 7px 0 7px 0;
}
.osahan-account-page-left .nav-link {
	padding: 18px 20px;
	border: none;
	font-weight: 600;
	color: #535665;
}
.osahan-account-page-left .nav-link i {
	width: 28px;
	height: 28px;
	background: #535665;
	display: inline-block;
	text-align: center;
	line-height: 29px;
	font-size: 15px;
	border-radius: 50px;
	margin: 0 7px 0 0px;
	color: #fff;
}
.osahan-account-page-left .nav-link.active {
	background: #f3f7f8;
	color: #282c3f !important;
}
.osahan-account-page-left .nav-link.active i {
	background: #282c3f !important;
}
.osahan-user-media img {
	width: 90px;
}
.card offer-card h5.card-title {
	border: 2px dotted #000;
}
.card.offer-card h5 {
	border: 1px dotted #daceb7;
	display: inline-table;
	color: #17a2b8;
	margin: 0 0 19px 0;
	font-size: 15px;
	padding: 6px 10px 6px 6px;
	border-radius: 2px;
	background: #fffae6;
	position: relative;
}
.card.offer-card h5 img {
	height: 22px;
	object-fit: cover;
	width: 22px;
	margin: 0 8px 0 0;
	border-radius: 2px;
}
.card.offer-card h5:after {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 4px solid #daceb7;
	content: "";
	left: 30px;
	position: absolute;
	bottom: 0;
}
.card.offer-card h5:before {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #daceb7;
	content: "";
	left: 30px;
	position: absolute;
	top: 0;
}
.card.offer-card .card-btn {
	font-size: 13px;
	color: rgb(0, 151, 136);
	vertical-align: top !important;
	border: 0px !important;
}
.card.offer-card .card-btn:hover {
	color: #0056b3;
}
.payments-item .media {
	align-items: center;
}
.payments-item .media img {
	margin: 0 40px 0 11px !important;
}
.reviews-members .media .mr-3 {
	width: 56px;
	height: 56px;
	object-fit: cover;
}
.order-list img.mr-4 {
	width: 70px;
	height: 70px;
	object-fit: cover;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	border-radius: 2px;
}
.osahan-cart-item p.text-gray.float-right {
	margin: 3px 0 0 0;
	font-size: 12px;
}
.osahan-cart-item .food-item {
	vertical-align: bottom;
}
/* Track Order Page */

.osahan-track-order-page {
	min-height: 676px;
}
.osahan-point {
	z-index: 1;
	width: 50%;
	border-radius: 50%;
	padding-bottom: 50%;
	pointer-events: none;
	background-color: rgba(225, 48, 8, 0.2);
	border: 1px solid rgb(0, 151, 136);
}
/* Footer */
.footer {
	background: #edf1f4;
}
.footer h6 {
	font-size: 14px;
	text-transform: uppercase;
}
.footer ul {
	margin: 0;
	padding: 0;
}
.footer ul li {
	line-height: 26px;
	list-style: outside none none;
}
.footer ul li a {
	color: #6c8293;
}
.search-links a {
	color: #666;
	font-size: 12px;
	line-height: 23px;
	margin: 0 2px;
}
.search-links {
	font-size: 11px;
}
footer {
	background-color: #f0f4f7;
}
/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.applycoupon {
		display: block;
	}
	.m-none {
		display: none;
	}
	.homepage-search-title {
		text-align: center;
	}
	.list-cate-page {
		margin: 22px 0 0 0;
	}
	.d-none-m {
		display: none;
	}
	.row.pt-2.pb-2 {
		padding-bottom: 0px !important;
		padding-top: 0px !important;
	}
	.row.pt-2 {
		padding-top: 0px !important;
	}
	.offer-card-horizontal .col-md-4.col-4.p-4 {
		padding: 0 0 0 15px !important;
	}
	.row.mt-4.pt-2 {
		margin-top: 0px !important;
		padding-top: 0px !important;
	}
	.homepage-add-section {
		padding: 0 13px;
	}
	.breadcrumb-osahan h1 {
		font-size: 34px;
	}
	.breadcrumb-osahan h6 {
		font-size: 15px;
	}
	.breadcrumb-osahan {
		padding-left: 15px;
		padding-right: 15px;
	}
	.reviews-members .media .mr-3 {
		width: 36px;
		height: 36px;
	}
	.total-like {
		font-size: 12px;
	}
	.restaurant-detailed-action-btn.float-right .btn {
		margin: 3px 0px;
		font-size: 13px;
	}
	.login .col-md-9.col-lg-8.mx-auto.pl-5.pr-5 {
		padding: 0 11px !important;
	}
	.login.d-flex.align-items-center.py-5 {
		padding: 0 !important;
	}
	.h5,
	h5 {
		font-size: 16px;
	}
	.homepage-add-section .col-md-3.col-6 {
		padding: 2px 2px;
	}
	.homepage-ad .item {
		padding: 0 2px;
	}
	.osahan-nav {
		padding: 15px 15px;
	}
	.navbar-toggler {
		padding: 5px 8px;
		border-radius: 2px;
	}
	.osahan-nav .nav-link {
		margin: 0px;
		text-align: center;
		padding: 14px 0 !important;
		border-bottom: 1px solid #eef0ef;
	}
	.osahan-nav .dropdown-item {
		text-align: center;
	}
	.osahan-slider {
		padding: 18px 0 0 0px !important;
	}
	.pt-5,
	.py-5 {
		padding-top: 3rem !important;
	}
	.pb-5,
	.py-5 {
		padding-bottom: 3rem !important;
	}
	.homepage-search-block {
		padding: 3rem 0 !important;
	}
	.mobile-none {
		display: none;
	}
	.card {
		margin-bottom: 15px !important;
	}
	p {
		font-size: 12px;
	}
	.restaurant-detailed-header-left img {
		float: none !important;
		margin: 0 0 14px 0 !important;
	}
	.restaurant-detailed-header-left {
		text-align: center;
	}
	.restaurant-detailed-header-right .btn-success {
		margin-top: 18px;
	}
	.restaurant-detailed-header-right.text-right {
		text-align: center !important;
	}
	.restaurant-detailed-action-btn.float-right {
		float: none !important;
		margin: auto;
		text-align: center;
		width: 100% !important;
		display: block;
		padding: 12px 0;
		border-bottom: 1px solid #dedede;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.offer-dedicated-nav .nav-item {
		flex: auto;
		border-bottom: 1px solid #ccc;
		padding: 0;
		margin: 0 11px !important;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.address-map.float-right.ml-5 {
		float: none !important;
		margin: 0 0 20px 0 !important;
	}
	.address-map.float-right.ml-5 iframe {
		width: 100%;
	}
	.osahan-track-order-detail p.text-gray.mb-5 {
		margin: 1px 0 11px 0 !important;
	}
	.osahan-account-page-left .nav-tabs {
		padding-left: 0px !important;
	}
	.osahan-account-page-left {
		height: auto !important;
		margin: 0 0 15px 0;
	}
	.order-list .float-right {
		float: none !important;
	}
	.row.mb-4 {
		margin-bottom: 0px !important;
	}
	.app {
		margin-bottom: 26px;
	}
	.footer ul li a {
		font-size: 12px;
	}
	.footer h6 {
		font-size: 13px;
		text-transform: capitalize;
	}

	.osahan-payment .col-sm-4.pr-0 {
		padding-right: 15px !important;
	}
	.osahan-payment .col-sm-8.pl-0 {
		padding-left: 15px !important;
		margin: 14px 0 0 0;
	}
	.p-5.osahan-invoice.bg-white.shadow-sm {
		padding: 15px !important;
	}
	.h3,
	h3 {
		font-size: 22px;
	}
	.p-5 {
		padding: 20px !important;
	}
	.osahan-account-page-right {
		padding: 0px !important;
		border: none;
		background: transparent !important;
		box-shadow: none !important;
		margin-top: 15px;
	}
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
	.applycoupon {
		display: block;
	}
	.homepage-add-section .col-md-3.col-6 {
		padding: 2px 2px;
	}
	.homepage-search-title {
		text-align: center;
	}
	.row.pt-2.pb-2 {
		padding-bottom: 0px !important;
		padding-top: 0px !important;
	}
	.row.pt-2 {
		padding-top: 0px !important;
	}
	.d-none-m {
		display: none;
	}
	.list-cate-page {
		margin: 22px 0 0 0;
	}
	.row.mt-4.pt-2 {
		margin-top: 0px !important;
		padding-top: 0px !important;
	}
	.offer-card-horizontal .col-md-4.col-4.p-4 {
		padding: 0 0 0 15px !important;
	}
	.homepage-add-section {
		padding: 0 13px;
	}
	.homepage-ad .item {
		padding: 0 2px;
	}
	.container {
		max-width: 100%;
	}
	.osahan-nav {
		padding: 15px 15px;
	}
	.navbar-toggler {
		padding: 5px 8px;
		border-radius: 2px;
	}
	.osahan-nav .nav-link {
		margin: 0px;
		text-align: center;
		padding: 14px 0 !important;
		border-bottom: 1px solid #eef0ef;
	}
	.osahan-nav .dropdown-item {
		text-align: center;
	}
	.osahan-slider {
		padding: 18px 0 0 0px !important;
	}
	.pt-5,
	.py-5 {
		padding-top: 3rem !important;
	}
	.pb-5,
	.py-5 {
		padding-bottom: 3rem !important;
	}
	.homepage-search-block {
		padding: 3rem 0 !important;
	}
	.mobile-none {
		display: none;
	}
	.card {
		margin-bottom: 15px !important;
	}
	p {
		font-size: 12px;
	}
	.restaurant-detailed-header-left img {
		float: none !important;
		margin: 0 0 14px 0 !important;
	}
	.restaurant-detailed-header-left {
		text-align: center;
	}
	.restaurant-detailed-header-right .btn-success {
		margin-top: 18px;
	}
	.restaurant-detailed-header-right.text-right {
		text-align: center !important;
	}
	.restaurant-detailed-action-btn.float-right {
		float: none !important;
		margin: auto;
		text-align: center;
		width: 100% !important;
		display: block;
		padding: 12px 0;
		border-bottom: 1px solid #dedede;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.offer-dedicated-nav .nav-item {
		flex: auto;
		border-bottom: 1px solid #ccc;
		padding: 0;
		margin: 0 11px !important;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.address-map.float-right.ml-5 {
		float: none !important;
		margin: 0 0 20px 0 !important;
	}
	.address-map.float-right.ml-5 iframe {
		width: 100%;
	}
	.osahan-payment .nav-link i {
		display: block;
		margin: 0 auto 10px auto;
	}
	.osahan-payment .nav-link {
		text-align: center;
	}
	.osahan-track-order-detail p.text-gray.mb-5 {
		margin: 1px 0 11px 0 !important;
	}
	.osahan-account-page-left .nav-tabs {
		padding-left: 0px !important;
	}
	.osahan-account-page-left {
		height: auto !important;
		margin: 0 0 15px 0;
	}
	.order-list .float-right {
		float: none !important;
	}
	.row.mb-4 {
		margin-bottom: 0px !important;
	}
	.app {
		margin-bottom: 26px;
	}
	.footer ul li a {
		font-size: 12px;
	}
	.footer h6 {
		font-size: 13px;
		text-transform: capitalize;
	}
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	.container {
		max-width: 100%;
	}
	.osahan-nav {
		padding: 15px 15px;
	}
	.navbar-toggler {
		padding: 5px 8px;
		border-radius: 2px;
	}
	.osahan-nav .nav-link {
		margin: 0px;
		text-align: center;
		padding: 14px 0 !important;
		border-bottom: 1px solid #eef0ef;
	}
	.osahan-nav .dropdown-item {
		text-align: center;
	}
	.osahan-slider {
		padding: 18px 0 0 0px !important;
	}
	.pt-5,
	.py-5 {
		padding-top: 3rem !important;
	}
	.pb-5,
	.py-5 {
		padding-bottom: 3rem !important;
	}
	.homepage-search-block {
		padding: 3rem 0 !important;
	}
	.card {
		margin-bottom: 15px !important;
	}
	p {
		font-size: 12px;
	}
	.restaurant-detailed-header-left img {
		float: none !important;
		margin: 0 0 14px 0 !important;
	}
	.restaurant-detailed-header-left {
		text-align: center;
	}
	.restaurant-detailed-header-right .btn-success {
		margin-top: 18px;
	}
	.restaurant-detailed-header-right.text-right {
		text-align: center !important;
	}
	.restaurant-detailed-action-btn.float-right {
		float: none !important;
		margin: auto;
		text-align: center;
		width: 100% !important;
		display: block;
		padding: 12px 0;
		border-bottom: 1px solid #dedede;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.offer-dedicated-nav .nav-item {
		margin: 0 8px !important;
	}
	.offer-dedicated-nav {
		text-align: center;
	}
	.address-map.float-right.ml-5 {
		float: none !important;
		margin: 0 0 20px 0 !important;
	}
	.address-map.float-right.ml-5 iframe {
		width: 100%;
	}
	.osahan-payment .nav-link i {
		display: block;
		margin: 0 auto 10px auto;
	}
	.osahan-payment .nav-link {
		text-align: center;
	}
	.osahan-track-order-detail p.text-gray.mb-5 {
		margin: 1px 0 11px 0 !important;
	}
	.osahan-account-page-left .nav-tabs {
		padding-left: 0px !important;
	}
	.osahan-account-page-left {
		height: auto !important;
		margin: 0 0 15px 0;
	}
	.order-list .float-right {
		float: none !important;
	}
	.row.mb-4 {
		margin-bottom: 0px !important;
	}
	.app {
		margin-bottom: 26px;
	}
	.footer ul li a {
		font-size: 12px;
	}
	.footer h6 {
		font-size: 13px;
		text-transform: capitalize;
	}
	.hidden {
		display: none;
	}
}
.arrow {
	height: 30px;
	border: solid rgb(0, 151, 136);
	border-width: 2px;
	display: inline-block;
	float: right;
}
.cursorPointer {
	cursor: pointer;
	font-size: 15px;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 5000.98px) {
	.container {
		max-width: 90%;
	}
}
@media screen and (min-width: 768px) {
	.content-mobile {
		display: none;
	}
	.content-desktop {
		display: block;
	}
	.cathide {
		display: none;
	}
	.content-mobile-head {
		display: none;
	}
	.fixed-content {
		position: fixed;
		bottom: 0;
		width: 100%;
	}
}
@media screen and (min-width: 768px) {
	.PopupPanel {
		position: sticky;
		top: 3%;
		-webkit-transition-duration: 1s;
		overflow-y: auto;
		min-height: 60px;
		overflow-x: hidden;
	}

	.PopupPanelCategory {
		position: sticky;
		top: 3%;
		-webkit-transition-duration: 1s;
		overflow-y: auto;
		min-height: 60px;
		overflow-x: hidden;
		background-color: #fff;
		margin-top: 10%;
		max-height: 5%;
		min-height: 400px;
	}
	/* width */
	.PopupPanelCategory::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	.PopupPanelCategory::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
		background: #f1f1f1;
	}

	/* Handle */
	.PopupPanelCategory::-webkit-scrollbar-thumb {
		background: #888;
		outline: 1px solid #888;
	}

	/* Handle on hover */
	.PopupPanelCategory::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	.PopupPanelCategory::-webkit-scrollbar-button {
		background-color: rgb(243, 242, 242);
		background-size: 10px 10px;
		background-repeat: no-repeat;
		background-position: center center;
		height: 10px;
		width: 1em;
		-webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
	}
	.PopupPanelCategory::-webkit-scrollbar-button:horizontal:increment {
		background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
	}

	.PopupPanelCategory::-webkit-scrollbar-button:end:increment {
		background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
	}

	.PopupPanelCategory::-webkit-scrollbar-button:start:decrement {
		background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
	}

	.itemPanel {
		overflow-y: auto;
		min-height: 200px;
		height: 200px;
		overflow-x: hidden; /* Hide horizontal scrollbar */
	}
	/* width */
	.itemPanel::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	.itemPanel::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
		background: #f1f1f1;
	}

	/* Handle */
	.itemPanel::-webkit-scrollbar-thumb {
		background: #888;
		outline: 1px solid #888;
	}

	/* Handle on hover */
	.itemPanel::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	.itemPanel::-webkit-scrollbar-button {
		background-color: rgb(243, 242, 242);
		background-size: 10px 10px;
		background-repeat: no-repeat;
		background-position: center center;
		height: 10px;
		width: 1em;
		-webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
	}
	.itemPanel::-webkit-scrollbar-button:horizontal:increment {
		background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
	}

	.itemPanel::-webkit-scrollbar-button:end:increment {
		background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
	}

	.itemPanel::-webkit-scrollbar-button:start:decrement {
		background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
	}

	.PopupPanelfixed {
		left: 90%;
		top: 50%;
		background-color: white;
		z-index: 100;
		height: 500px;
		margin-top: -200px;

		width: 350px;

		overflow-y: scroll;
		max-height: 100%;
	}
}
/* Extra large devices (large desktops, 1200px and up) */
@media screen and (max-width: 768px) {
	.content-mobile {
		display: block;
	}
	.content-desktop {
		display: none;
	}
	.cathide {
		display: block;
	}
	.content-mobile-head {
		display: none;
	}
	.fixed-content {
		position: fixed;
		bottom: 0;
		width: 100%;
		float: right;
	}
}
.top {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 23px;
}
.smallitem {
	font-size: 13px;
	color: #7a7e8a;
}
.applycoupon {
	display: none;
}
@media screen and (max-width: 768px) {
	.cathide {
		display: none;
	}
	.content-mobile {
		display: block;
	}
	.content-mobile-head {
		display: block;
	}
}
@media screen and (max-width: 768px) {
	.margin-mob {
	}
	.restaurant-detailed-banner .img-fluid.cover {
		height: 424px;
		object-fit: cover;
	}
	.marginmob {
		margin-top: -40px;
	}
	.applycoupon {
		display: block;
	}
}
.pointer-link {
	cursor: pointer;
}

.pac-container {
	z-index: 10000 !important;
}

.delivery {
	background-color: #e4f5ec;
}
.border-none {
	outline: none;
	border: none;
	border-color: #fff !important;
}
.radio-toolbar input[type="radio"] {
	opacity: 0;
	position: fixed;
	width: 0;
	cursor: pointer;
}
.radio-toolbar label {
	display: inline-block;
	background-color: #ddd;
	padding: 10px 20px;
	font-family: sans-serif, Arial;
	font-size: 16px;
	border: 2px solid #444;
	border-radius: 4px;
}
.radio-toolbar input[type="radio"]:checked + label {
	background-color: #bfb;
	border-color: #4c4;
	cursor: pointer;
}
.radio-toolbar input[type="radio"]:focus + label {
	border: 2px dashed #444;
	cursor: pointer;
}
.radio-toolbar label:hover {
	background-color: #dfd;
}

.ordercolor {
	background-color: #3868fb;
}
.myfont {
	font-size: 11px;
}
.myfont1 {
	font-size: 11px;
	color: #000;
}
.tip-number-input {
	width: 64px;
	text-align: center;
	margin-bottom: 7px;
	background: #fffae6;
	border: none;
	color: #000;
	height: 24px;
	border-radius: 0px;
	vertical-align: bottom;
	border-color: #fffae6;
	font-size: 16px;
}
.addresslocat {
	font-size: 16px;
}
.input-min-width-95p {
	min-width: 95%;
}
.react-datepicker-ignore-onclickoutside {
	min-width: 95%;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
	display: block;
	width: 100%;
	height: 75%;
	font-size: 16px;
}
.fadeOut {
	opacity: 0;
	width: 0;
	height: 0;
	transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
	opacity: 1;
	width: 100px;
	height: 100px;
	transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
.coupnText {
	width: 40%;
	padding: 2px 10px;
	margin: 8px 0;
	font-size: 16px;
	box-sizing: border-box;
}
.backgrd {
	width: 100%;
	height: 70px;
	padding: 7px;
	text-align: center;
	background: #19c430;
	border: none;
	color: #fff;
	font-size: 16px;
	display: block;
}
.backgrd_unreached {
	width: 100%;
	height: 70px;
	padding: 7px;
	text-align: center;
	background: #ec7200;
	border: none;
	color: #000;
	font-size: 16px;
	display: block;
}
.error_delivery {
	width: 100%;
	height: 70px;
	padding: 7px;
	text-align: center;
	background: #ec0400;
	border: none;
	color: #000;
	font-size: 16px;
	display: block;
}
.imgspan {
	height: 70px;
}
.btnwidth {
	height: 32px;
	margin-bottom: 5px;
	border: none;
}
.tipcolour {
	background: #3868fb;
	color: white;
}
.shedule-text-colour {
	color: #d85e42;
	font-size: 12px;
	font-weight: bold;
}
.item-center {
	display: grid;
	grid-auto-flow: column;
	gap: 4px;
	font-size: 16px;
	text-align: center;
	align-items: center;
	justify-items: center;
}
.moneris-div {
	position: relative;
}

.moneris-span {
	position: absolute;
	top: 0;
	left: 0;
}
#outerDiv {
	width: 400px;
	height: 300px;
}
.stripe {
	color: white;
}
.StripeCheckout {
	padding: 0.7rem 2rem;
	width: 100%;
	margin: 1rem 0;
	color: white;
	font-weight: bold;
	font-size: medium;
	background-color: #30d54e;
	border: 0;
	border-radius: 5px;
	box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
	transition: box-shadow 500ms;
	cursor: pointer;
}

.stripe-pay-button {
	background-color: #0a2156 !important; /* Your custom color here */
	color: #ffffff !important;
}

/* ior */
.ior-modal-content {
	border-radius: 6px;
	background: white;
	min-height: 550px;
	max-height: 550px;
}

.ior-modal-content1 {
	border-radius: 6px;
	background: white;
	/* min-height: 550px; */
	max-height: 550px;
}

.ior-modal-content2 {
	border-radius: 20px !important;
}

.ior-combo-card {
	padding: 0.3rem !important;
}
.ior-bundle-image-on-modal {
	border-radius: 4px !important;
}

.ior-modal-backdrop2 {
	/* background: rgb(0, 151, 136);
    opacity: 0.9 !important; */
}

.custom-date-picker {
	font-size: 16px !important;
	border-radius: 10px !important;
	min-height: 38px !important;
	padding: 0px !important;
	padding-left: 5px !important;
	border: solid 1px rgb(209, 207, 207);
}

/* In your global CSS file or style block */

/* WebKit (Chrome, Safari, newer Edge) */
*::-webkit-scrollbar {
	width: 10px;
}

*::-webkit-scrollbar-track {
	background: #f0f0f0; /* Light background color */
}

*::-webkit-scrollbar-thumb {
	background-color: lightgrey; /* Scrollbar thumb color */
	border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: lightgrey; /* Darker color on hover */
}

/* Firefox */
* {
	scrollbar-width: thin; /* "thin" or "auto" */
}

* {
	scrollbar-color: lightgrey #f0f0f0; /* Thumb color and track color */
}

.custom-tooltip .arrow {
	border: solid transparent !important; /* Change this to the desired border color */
}
.custom-tooltip {
	width: 250px !important;
}
